<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >

      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import PreviewImage from '@/components/PreviewImage'
import { formatCurrency } from '@/utils/filter'
import { findTenantPackagesItems } from '@/api/packages_item'

export default {
  name: 'SystemPackageItemList',
  components: {
    Pagination,
    PreviewImage
  },
  props: {
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      previewVisible: false,
      previewImages: [],
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'name',
          fixed: 'left',
          width: 100
        },
        {
          title: '类型',
          dataIndex: 'item_type'
        },
        {
          title: '计价单位',
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          dataIndex: 'count'
        },
        {
          title: '价格(元)',
          dataIndex: 'price',
          customRender: formatCurrency
        },
        {
          title: '图片',
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '项目介绍',
          width: 100,
          dataIndex: 'remark',
          ellipsis: true
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    fetchData() {
      this.loading = true
      const queryData = Object.assign({}, this.query, { package_id: this.record.id, tenant_id: this.record.tenant_id })
      findTenantPackagesItems(queryData).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
