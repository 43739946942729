<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="800px"
      title="套餐详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>
      <a-row>
        <a-col :span="7">
          <a-descriptions :column="1" class="custom-descriptions custom-descriptions-max-7">
            <a-descriptions-item label="套餐名称">{{ record.name }}</a-descriptions-item>
            <a-descriptions-item label="套餐价格(元)">{{ record.price | formatCurrency }}</a-descriptions-item>
            <a-descriptions-item label="套餐介绍">{{ record.remark }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="9">
          <a-descriptions :column="1" class="custom-descriptions-max-7 custom-descriptions">
            <a-descriptions-item label="状态">{{ record.effective? '有效':'无效' }}</a-descriptions-item>
            <a-descriptions-item label="创建时间">{{ record.created_at }}</a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="8">
          <a-descriptions :column="1" class="custom-descriptions">
            <a-descriptions-item label="图片">
              <template v-if="record.images === null" />
              <template>
                <img class="set-img" v-for="(item, index) in record.images" :key="index" :src="item.url" @click="showImg(record)">
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
      <a-divider />
      <system-package-item-list :record="record" />
      <preview-image
        :visible.sync="previewVisible"
        :images.sync="previewImages"
      />
    </a-modal>
  </div>
</template>

<script>
import PreviewImage from '@/components/PreviewImage'
import SystemPackageItemList from '@/views/tenants/package_items/index'

export default {
  name: 'ShowPackageInfo',
  components: {
    SystemPackageItemList,
    PreviewImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  created() {
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data() {
    return {
      previewVisible: false,
      previewImages: []
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },
    closeModal() {
      this.isShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.set-img {
  width: 40%;
}
</style>
